import { PublicClientApplication } from '@azure/msal-browser';
export const pca = new PublicClientApplication({
    auth: {
      clientId: "d8a815ff-2d47-4c99-8408-6a4ea8302e34",
      authority: "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c",
      redirectUri: "/"
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPII) => {
          console.log('System auth error *****', message);
        },
        logLevel: "info"
      },
      tokenRenewalOffsetSeconds: 1800
    }
  });